function loadScript(src, id, onload) {
  // Create script
  let script = document.createElement("script");
  if (id) script.id = id;
  script.src = src;
  script.async = true;
  if (onload) script.onload = onload;
  // script.onerror = callback;

  // Add script to document body
  document.body.appendChild(script);
}

function localStorageSet(key, value) {
  try {
    if (typeof localStorage == "undefined") return false;
    localStorage.setItem(key, value);
    return true;
  } catch (err) {
    return false;
  }
}

function localStorageGet(key) {
  try {
    if (typeof localStorage == "undefined") return false;
    return localStorage.getItem(key);
  } catch (err) {
    return false;
  }
}

function retry(fn, triesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (--triesLeft > 0)
          setTimeout(
            () => retry(fn, triesLeft, interval).then(resolve, reject),
            interval
          );
        else reject(error);
      });
  });
}

export {
  loadScript,
  localStorageGet,
  localStorageSet,
  retry,
};
