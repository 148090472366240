import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import cardReducer from "./cardReducer";
import currReducer from "./currReducer";
import languageReducer from "./languageReducer";
import amountReducer from "./amountReducer";

/**
 * form: state of redux-form (for usage, refer to redux-form documentations)
 *
 * card: Object {
 *   cardType: null | values from cardTypes.js
 * }
 *
 * curr: Object {      // only used for CP
 *   currType: "CAD",  // currency to load
 *   currMethod: "CAD" // the currency of the amount entered in the input field
 * }
 * User chooses the currency to load first, (currType)
 * then if that currency is a foreign currency the user can choose to enter the
 * load amount in CAD or in the selected foreign currency (currMethod)
 *
 * amount: number
 * amount to be loaded to the prepaid cards, in CAD
 * for Cash Passport, this number might be different from what the user entered
 * in the form input field (amounts in foreign currencies will be converted to CAD)
 *
 * lang: string
 *   "en" | "fr"
 */
export default combineReducers({
  form: formReducer,
  card: cardReducer,
  curr: currReducer,
  amount: amountReducer,
  lang: languageReducer,
});
