import { SET_AMOUNT, CLEAR_AMOUNT } from "../actions/types";

const reducer = (state = 0, action) => {
  switch (action.type) {
    case SET_AMOUNT:
      return action.payload;
    case CLEAR_AMOUNT:
      return 0;
    default:
      return state;
  }
};

export default reducer;
