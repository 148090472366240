import { CURR_TYPE } from "../actions/types";

const INITIAL_STATE = {
  currType: "CAD",
  currMethod: "CAD",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CURR_TYPE:
      return {
        ...state,
        currType: action.payload,
        currMethod: action.payload2,
      };
    default:
      return state;
  }
};

export default reducer;
