import { isDevelopment } from "../hostingEnv";

let url;
const token = "pVQ5GkXZKtC3bjshP6UvE8aXCTKN9V";
const Processor_CashPassport = "TMUACC";
const UPC_CashPassport = "063491094156";

export const setUrl = (apiUrl) => {
  url = apiUrl;
};

const makeCall = async (type, data) => {
  const req = {
    Type: type,
    ApiToken: token,
    ...data,
  };

  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(req),
  })
    .then((res) => res.json())
    .catch((err) => err);

  if (isDevelopment()) console.log(res);

  return res;
};

export default makeCall;

const RegisterLoading = async (data) => {
  data.Email = data.UserEmail.toLowerCase();
  return await makeCall("RegisterLoading", data);
};

export const LoadingCashPassport = async (
  email,
  firstName,
  lastName,
  cardNumber,
  secret,
  birthdate,
  lang = "en",
  reCaptcha
) => {
  const data = {
    UserEmail: email,
    Processor: Processor_CashPassport,
    CardNumber: cardNumber,
    UPC: UPC_CashPassport,
    Birthday: birthdate,
    Language: lang,
    FirstName: firstName,
    LastName: lastName,
    Secret: secret,
    ReCaptchaToken: reCaptcha,
    Platform: 'Web'
  };
  return await RegisterLoading(data);
};