import Amplitude from "amplitude-js";
import { isDevelopment, isLocal } from "../hostingEnv";

const AMPLITUDE_SESSION_TIMEOUT = 20 * 60 * 1000;

export const startAmplitude = (key, version) => {
  Amplitude.getInstance().init(key, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    sessionTimeout: AMPLITUDE_SESSION_TIMEOUT,
  });
  if (version) Amplitude.getInstance().setVersionName(version);
};

export const setAmpUser = (email) => {
  logToConsole(`[AMPLITUDE] Setting user ID: ${email}`);
  Amplitude.getInstance().setUserId(email);
}

export const getAmpSessionID = () => Amplitude.getInstance().getSessionId();

export const setUserProp = (cardName, registeredEmail, vettedStatus) => {
  const properties = {
    cardType: cardName,
    "Registered Email": registeredEmail,
    "Vetted Status": vettedStatus,
  };
  logToConsole("[AMPLITUDE] Setting user property:", properties);
  Amplitude.getInstance().setUserProperties(properties);
};

export const logAmpEvent = (event, properties = null) => {
  logToConsole(
    `Logging event: ${event}`,
    properties ? "With properties:" : "",
    properties ? properties : ""
  );
  if (properties === null) {
    Amplitude.getInstance().logEvent(event);
  } else {
    Amplitude.getInstance().logEvent(event, properties);
  }
};

const logToConsole = (...args) => {
  if (isDevelopment() || isLocal()) console.log(...args);
}

export const events = {
  /*CHAT WIDGET*/
  GENESYS_CHAT_STARTED: "GENESYS_CHAT_STARTED",
  GENESYS_CHAT_ENDED: "GENESYS_CHAT_ENDED",

  /*START OF SHARED*/
  /*START OF ERRORS*/
  ERROR_OCCURRED: "ERROR_OCCURRED",
  USER_ENTERED_INVALID_VALUE: "USER_ENTERED_INVALID_VALUE",
  /*END OF ERRORS*/

  /*START OF NAVBAR*/
  USER_CLICKED_NAVBAR_LOGO_HOME: "USER_CLICKED_NAVBAR_LOGO_HOME",
  USER_CLICKED_NAVBAR_SIDEBAR: "USER_CLICKED_NAVBAR_SIDEBAR",
  USER_CHANGED_LANGUAGE: "USER_CHANGED_LANGUAGE",
  USER_CLICKED_NAVBAR_HELP: "USER_CLICKED_NAVBAR_HELP",
  USER_CLICKED_NAVBAR_ONLINE: "USER_CLICKED_NAVBAR_ONLINE",
  USER_CLICKED_NAVBAR_MOBILE: "USER_CLICKED_NAVBAR_MOBILE",
  USER_CLICKED_NAVBAR_IN_PERSON: "USER_CLICKED_NAVBAR_IN_PERSON",
  /*END OF NAVBAR*/
  /*START OF FOOTER*/
  USER_CLICKED_FOOTER_ONLINE_CP: "USER_CLICKED_FOOTER_ONLINE_CP",
  USER_CLICKED_FOOTER_MOBILE_CP: "USER_CLICKED_FOOTER_MOBILE_CP",
  USER_CLICKED_FOOTER_IN_PERSON_CP: "USER_CLICKED_FOOTER_IN_PERSON_CP",
  USER_CLICKED_FOOTER_CP_IMAGE: "USER_CLICKED_FOOTER_CP_IMAGE",
  USER_CLICKED_FOOTER_MAILTO: "USER_CLICKED_FOOTER_MAILTO",
  USER_CLICKED_FOOTER_TERMS_AND_CONDITIONS: "USER_CLICKED_TERMS_AND_CONDITIONS",
  USER_CLICKED_FOOTER_POLICY: "USER_CLICKED_FOOTER_POLICY",
  USER_CLICKED_FOOTER_HELP: "USER_CLICKED_FOOTER_HELP",
  USER_CLICKED_FOOTER_ACCESSIBILITY: "USER_CLICKED_FOOTER_ACCESSIBILITY",
  /*END OF FOOTER */

  USER_CLICKED_ANDROID_STORE: "USER_CLICKED_ANDROID_STORE",
  USER_CLICKED_APPLE_STORE: "USER_CLICKED_APPLE_STORE",

  /*END OF SHARED*/
  /*START OF LANDING PAGE*/

  USER_CLICKED_IN_PERSON_FIND_CP_LOCATION:
    "USER_CLICKED_IN_PERSON_FIND_CP_LOCATION",
  USER_CLICKED_SUPPORTED_CARD: "USER_CLICKED_SUPPORTED_CARD",
  USER_VIEWED_MAIN_LANDING_PAGE: "USER_VIEWED_MAIN_LANDING_PAGE",
  USER_VIEWED_CASH_PASSPORT_LANDING_PAGE:
    "USER_VIEWED_CASH_PASSPORT_LANDING_PAGE",

  /*END OF LANDING PAGE*/

  /*START OF CARD LOGIN*/
  USER_VIEWED_CARD_LOGIN_PAGE: "USER_VIEWED_CARD_LOGIN_PAGE",

  USER_SUBMITTED_CARD_LOGIN_SUCCESSFULLY:
    "USER_SUBMITTED_CARD_LOGIN_SUCCESSFULLY",
  USER_CLICKED_CARD_LOGIN_CONTINUE: "USER_CARD_LOGIN_CLICKED_CONTINUE",
  USER_CLICKED_CARD_LOGIN_INFO_CARD_BACK:
    "USER_CLICKED_CARD_LOGIN_INFO_CARD_BACK",

  USER_CLICKED_CARD_LOGIN_SWITCH_CARD: "USER_CLICKED_CARD_LOGIN_SWITCH_CARD",

  CARD_LOGIN_RECAPTCHA_SUCCESS: "CARD_LOGIN_RECAPTCHA_SUCCESS",
  CARD_LOGIN_RECAPTCHA_CANCEL: "CARD_LOGIN_RECAPTCHA_CANCEL",
  GOOGLE_RECAPTCHA_ERROR: "GOOGLE_RECAPTCHA_ERROR",
  /*END OF CARD LOGIN*/

  /*START OF CARD LOAD*/
  USER_VIEWED_CARD_LOAD_PAGE: "USER_VIEWED_CARD_LOAD_PAGE",
  USER_CLICKED_CARD_LOAD_CURRENCY_CHANGE_DROPDOWN:
    "USER_CLICKED_CARD_LOAD_CURRENCY_CHANGE_DROPDOWN:",
  USER_CLICKED_CARD_LOAD_CURRENCY_CHANGE_BUTTON:
    "USER_CLICKED_CARD_LOAD_CURRENCY_CHANGE_BUTTON",
  USER_RECEIVED_CARD_LOAD_ERROR_CP: "USER_RECEIVED_CARD_LOAD_ERROR_CP",
  USER_CLICKED_CARD_LOAD_PAY_NOW: "USER_CLICKED_CARD_LOAD_PAY_NOW",

  CARD_LOAD_PAY_NOW_SUCCESS: "CARD_LOAD_PAY_NOW_SUCCESS",

  USER_CLICKED_COPY_ETRANSFER_INSTRUCTIONS:
    "USER_CLICKED_COPY_ETRANSFER_INSTRUCTIONS",
  USER_CLICKED_FAQ_ETRANSFER_INSTRUCTIONS:
    "USER_CLICKED_FAQ_ETRANSFER_INSTRUCTIONS",
  USER_CLICKED_CANT_FIND_ETRANSFER_INSTRUCTIONS:
    "USER_CLICKED_CANT_FIND_ETRANSFER_INSTRUCTIONS",

  USER_CLICKED_CARD_LOAD_LEARN_MORE: "CARD_LOAD_USER_CLICKED_LEARN_MORE",

  USER_CLICKED_ETRANSFER_BANK_INSTRUCTIONS:
    "USER_CLICKED_ETRANSFER_BANK_INSTRUCTIONS",
  USER_CLICKED_ETRANSFER_FAQ: "USER_CLICKED_ETRANSFER_FAQ",
  USER_CLICKED_CARD_SHUTDOWN_LEARN_MORE: "USER_CLICKED_CARD_SHUTDOWN_LEARN_MORE",

  /*END OF CARD LOAD*/
  /*START OF ERROR PAGES*/
  USER_VIEWED_404_ERROR_PAGE: "USER_VIEWED_404_ERROR_PAGE",
  USER_VIEWED_ERROR_PAGE: "USER_VIEWED_ERROR_PAGE",

  /*END OF ERROR PAGES*/

  /*START OF HELP PAGE*/
  USER_VIEWED_HELP_PAGE: "USER_VIEWED_HELP_PAGE",

  USER_OPENED_HELP_QUESTION: "USER_OPENED_HELP_QUESTION",

  USER_RATED_HELP_ANSWER: "USER_RATED_HELP_ANSWER",
  /*END OF HELP PAGE*/
  /*START OF FEEDBACK PAGE*/
  USER_VIEWED_THANK_YOU_FOR_FEEDBACK_PAGE:
    "USER_VIEWED_THANK_YOU_FOR_FEEDBACK_PAGE",
  USER_VIEWED_FEEDBACK_PAGE: "USER_VIEWED_FEEDBACK_PAGE",
  // USER_SUBMITTED_REVIEW: "USER_SUBMITTED_REVIEW", // this event will be sent from the backend
  /*END OF FEEDBACK PAGE*/

  /*START OF VETTED STATUS*/
  USER_VIEWED_VETTED_STATUS: "USER_VIEWED_VETTED_STATUS",
  /*END OF VETTED STATUS*/

  /*START OF TERMS PAGE*/
  USER_VIEWED_TERMS_PAGE: "USER_VIEWED_TERMS_PAGE",
  /*END OF TERMS PAGE*/
};
