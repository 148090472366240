import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import * as Sentry from "@sentry/react";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
} from "react-switch-lang";

import reducers from "./reducers";
import { startAmplitude, logAmpEvent, events } from "./Amplitude/Amplitude";
import { isProduction } from "./hostingEnv";
import { setUrl } from "./api-client";
import { retry } from "./utils";
import English from "./languages/English.json";
import French from "./languages/French.json";

setTranslations({
  en: English,
  fr: French,
});
setDefaultLanguage("en");
setLanguageCookie('language', 'samesite=strict;secure;');

startAmplitude(process.env.REACT_APP_AMPLITUDE_API_KEY, process.env.REACT_APP_VERSION);

if (isProduction()) {
  Sentry.init({
    dsn: "https://0b31467f491443eba955b0feb990d5f9@o292223.ingest.sentry.io/5266367",
    release: process.env.REACT_APP_VERSION,
    beforeSend: (event, { originalException: err }) => {
      if (typeof err === "string" && err.includes("Timeout")) return null;

      if (err) {
        logAmpEvent(events.ERROR_OCCURRED, {
          Error: err.name,
          Message: err.message,
          Page: window.location.pathname,
        });
      }

      return event;
    },
  });
}

let lang = "en";

if (
  window.location.pathname.toLowerCase().includes("/chat")
) {
  let searchParam = new URLSearchParams(window.location.search);
  let lang = searchParam.get("lang") || "en";
  let theme = searchParam.get("theme") || "light";
  window.location.href = `https://help.paymentsource.net/mobilechat/zenwallet/${lang}/${theme}`;
} else {
  setUrl(process.env.REACT_APP_ZENWALLET_API_URL);
  let queryLang = new URLSearchParams(window.location.search).get("lang");
  if (queryLang === "en" || queryLang === "fr") lang = queryLang;

  let store;

  try {
    if (typeof localStorage == "undefined") {
      lang = lang || "en";
    } else {
      if (lang) {
        // queryLang takes precedence over saved lang in localStorage
        localStorage.setItem("language", lang);
      } else {
        // if there's no lang passed in from the URL, try to retrieve saved lang
        lang = localStorage.getItem("language");

        // if there's no saved lang, save en
        if (lang !== "en" && lang !== "fr") {
          lang = "en";
          localStorage.setItem("language", "en");
        }
      }
    }
  } catch (err) { }

  document.documentElement.setAttribute("lang", lang); // set <html> lang attr
  store = createStore(reducers, { lang });
  // handle lang change
  store.subscribe(() => {
    let newLang = store.getState().lang;
    if (newLang !== lang) {
      lang = newLang; // set prev lang
      document.documentElement.setAttribute("lang", newLang); // set <html> lang attr
      try {
        if (typeof localStorage != "undefined")
          localStorage.setItem("language", newLang); // save lang
      } catch (err) { }
    }
  });

  retry(() =>
    import("./components/App").then(({ default: App }) =>
      ReactDOM.render(
        <Provider store={store}>
          <App lang={lang} />
        </Provider>,
        document.getElementById("root")
      )
    )
  );
}
